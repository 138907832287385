var menuMobile = function()
{
	var open = $('body > header .actions a.open'),
		close = $('body > header .actions a.close'),
		menu = $('body > header .menu-principal-container');

	if($(window).width() <= 1440)
	{
		open.on('click',function(e){
			e.preventDefault();
			$(this).removeClass('activated');
			menu.addClass('opened');
			close.addClass('activated');
		});
		close.on('click',function(e){
			e.preventDefault();
			$(this).removeClass('activated');
			menu.removeClass('opened');
			open.addClass('activated');
		});

		function openSubmenu()
		{
			var altura = 0;

			$('body > header #menu-principal ul.sub-menu li').each(function(){
				altura += $(this).height() + 1;
			});

			$('body > header #menu-principal ul.sub-menu').stop(true,true).animate({height : altura},300);

		}

		function closeSubmenu()
		{
			var altura = 0;

			$('body > header #menu-principal ul.sub-menu').stop(true,true).animate({height : altura},300);

		}

		$('body > header #menu-principal li.has-submenu > a').on('click',function(e){
			e.preventDefault();
			if(!$(this).hasClass('opened'))
			{
				$(this).addClass('opened');
				openSubmenu();
			}
			else
			{
				$(this).removeClass('opened');
				closeSubmenu();
			}
		});

	}
}

var slides = function()
{
	var swiper = new Swiper('#bannerHome .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        loop: true,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        speed: 2000
    });

    var swiperPersonal = new Swiper('.personalPage article .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        loop: false,
        speed: 1000,
        prevButton: '.personalPage article .arrow-left',
        nextButton: '.personalPage article .arrow-right',
    });

    var slides = 0;

    if($('.personalPage article .swiper-slide').length > 0)
    {
    	$('.personalPage article .swiper-slide').each(function(){
	    	slides++;
	    });
	     if(slides <= 1)
	    {
	    	$('.personalPage article div.navigation').css({'display':'none'});
	    }
    }

}

var rescaleVideo = function()
{
	var bgImg = $('#muteYouTubeVideoPlayer');
	winHeight = $('section#servicosHome').height();
	winWidth = $('section#servicosHome').width();
	
	var imgwidth = 1240;
	var imgheight = 698;
	
	var widthratio = winWidth / imgwidth;
	var heightratio = winHeight / imgheight;
	
	var widthdiff = heightratio * imgwidth;
	var heightdiff = widthratio * imgheight;
	
	alignHeight = (winHeight - heightdiff) / 3;
	alignWidth = (winWidth - widthdiff) / 3;
	
	bgImg.css({
		width: heightdiff > winHeight ? winWidth+'px' : widthdiff+'px',
		height: heightdiff > winHeight ? heightdiff+'px' : winHeight+'px',
		marginTop: heightdiff > winHeight ? (alignHeight <= 0 ? alignHeight+'px' : 0) : '',
		marginLeft: heightdiff > winHeight ? '' : (alignWidth <= 0 ? alignWidth+'px' : 0)
	});
}

var propBox = function()
{
	var resizeBox = function(obj)
	{
		var nH = (315 * obj.width()) / 560;
		obj.css({'clear':'both'}).height(nH).stop().animate({opacity:1},300);
	}		
	$('iframe,.propBox').each(function(){
		resizeBox($(this));
	});
}

var sendMail = function(settings)
{
	
	/* OBJETOS */

	var sucessoPath = settings.sucessoPath,
		path = settings.path,
		sendmail = path+'/envia_email.php',
		$form = $(settings.form),
		$lightbox = $(settings.lightbox),
		numRand = Math.floor(Math.random()*5),
		numRand2 = Math.floor(Math.random()*5),
		total = numRand + numRand2;

		$form.find('.captcha strong.item1').text(numRand);
		$form.find('.captcha strong.item2').text(numRand2);

	/* FUNÇÕES LIGHTBOX */

	function fechar()
	{
		$lightbox.find('a.fechar').click(function()
		{
			$lightbox.stop().animate({opacity:0},300,function(){
				$(this).css({'display':'none'});
				$(this).find('div').css({'opacity':0});
			});

			$('body').css({'overflow':'auto'});

			$form.find('.captcha input[type="submit"]').css({'display':'inline-block'});
			$form.find('.captcha img.preloader').css({'display':'none'});

			return false;
		});
	}

	/* FUNÇÕES DE RESPOSTAS */

	function sucesso()
	{
		var html = '<div class="sucesso">';
				html += '<p>'+settings.sucesso+'</p>';
				html += '<a href="#" title="FECHAR" class="fechar">X</a>';
			html += '</div>';
		$lightbox.html(html).css({'display':'block'}).stop().animate({opacity:1},300,function(){
			$(this).find('div').stop().animate({opacity:1},300);
			$('body').css({'overflow':'hidden'});
			fechar();
		});
		$form.find('input[type="reset"]').trigger( "click" );
	}

	function erro()
	{
		var html = '<div class="erro">';
				html += '<p>'+settings.erro+'</p>';
				html += '<a href="#" title="FECHAR" class="fechar">X</a>';
			html += '</div>';
		$lightbox.html(html).css({'display':'block'}).stop().animate({opacity:1},300,function(){
			$(this).find('div').stop().animate({opacity:1},300);
			$('body').css({'overflow':'hidden'});
			fechar();
		});
	}

	/* DISPAROS DE FORMULÁRIOS */

	$form.on('submit',function(){
		if($(this).find('input[name="captcha"]').val() == total){
			$(this).find('.captcha input[type="submit"]').css({'display':'none'});
			$(this).find('.captcha img.preloader').css({'display':'inline-block'});
			$.post(sendmail,$(this).serialize(), function(status) {
				window.location.href = sucessoPath;
				//sucesso();
			});
		} else {
			erro();
		}
		return false;
	});

}

var calendarios = function()
{

	var $calendarios = $('#cursoSingle .calendarios .calendars');

	if($calendarios.length > 0)
	{
		var now = new Date();
	    var year = now.getFullYear();
	    var month = now.getMonth() + 1;
	    var date = now.getDate();

	    $calendarios.each(function(){

	    	var dates = $(this).attr('data-date').split('-');

		    var $ca = $(this).calendar({
		        data: null,
		        weekArray:  ['dom','seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
		        monthArray: ['janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		        date: new Date(dates[0], dates[1] - 1, dates[2])
		    });

		});
	}
}

var calendarioStylize = function()
{
	var $calendarios = $('#cursoSingle .calendarios .calendars'),
		arrMeses = ['janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

	$('a.calendar-display').on('click',function(){
		return false;
	});

	function makeChanges(obj,mes,ano)
	{
		obj.find('a.calendar-display').text(arrMeses[mes]+' / '+ano);
		obj.find('div.calendar-arrow').css({'display':'none'});
	}

	function weekend(obj,status)
	{
		if(status == 1)
		{
			obj.find('.days li.now').next('li').addClass('now');
		}
	}

	if($calendarios.length > 0)
	{
		$calendarios.each(function(){
	    	makeChanges($(this), $(this).attr('data-date').split('-')[1] - 1,$(this).attr('data-date').split('-')[0]);
	    	weekend($(this), $(this).attr('data-weekend'));
		});
	}

}

var ConactForm = function()
{
	/* Validation Events for changing response CSS classes */
	document.addEventListener( 'wpcf7invalid', function( event ) {
		$('.wpcf7-response-output').addClass('alert alert-danger');
	}, false );
	document.addEventListener( 'wpcf7spam', function( event ) {
		$('.wpcf7-response-output').addClass('alert alert-warning');
	}, false );
	document.addEventListener( 'wpcf7mailfailed', function( event ) {
		$('.wpcf7-response-output').addClass('alert alert-warning');
	}, false );
	document.addEventListener( 'wpcf7mailsent', function( event ) {
		$('.wpcf7-response-output').addClass('alert alert-success');
	}, false );

	$('input.phone').mask("(00) 00000-0009", {clearIfNotMatch: true, placeholder: "(__) _____-____"});
	$('input.phoneComercial').mask("(00) 00000-0009", {clearIfNotMatch: true, placeholder: "(__) _____-____"});
	$('input.cpf').mask("000.000.000-09", {clearIfNotMatch: true, placeholder: "___.___.___-__"});

	/* POPULATE SELECTS */


	if($('.form.inscricao').length > 0)
	{
		let API = '';

		if(window.location.hostname == 'localhost')
			API = 'http://localhost:60011/wp-json/wp/v2/post_cursos/';
		else
			API = 'https://caft.com.br/wp-json/wp/v2/post_cursos/';
		
		$.ajax(
		{
			url: API,
			dataType: 'json',
			success:function(posts)
			{
				const contentCurso 	= $('.form.inscricao');
				const cursoID 		= contentCurso.data('id');
				const dataInicio 	= contentCurso.data('cusrsoinicio');

				let postsResult 	= [];
				let cursoSolicitado = [];

				let htmlCursos = '<option value="">Selecionar curso</option>';
				let htmlPagamento = '';
				
				posts.forEach( function(post)
				{
					let selected = cursoID && cursoID != "" && parseInt(cursoID) == post.id ? 'selected' : '';

					htmlCursos += '<option value="'+post.title.rendered+'" selected="'+selected+'">'+post.title.rendered+'</option>';

					if(cursoID && cursoID != "" && parseInt(cursoID) == post.id)
					{
						htmlPagamento += '<option value="" selected="selected">Selecionar forma de pagamento</option>';

						post.acf.formas_de_pagamento_deste_curso.forEach( function(pagamento)
						{
							htmlPagamento += '<option value="'+pagamento.metodo_de_pagamento+'">'+pagamento.metodo_de_pagamento+'</option>';
						});
					}
				});

				/* OPTIONS CURSOS */

				contentCurso.find('#selectOpcoesCursos').html(htmlCursos);

				if(htmlPagamento != '')
				{
					contentCurso.find('#selectOpcoesPagamento').html(htmlPagamento);
				}
				else
				{
					let pagamento = `
						<option value="" selected="selected">Selecionar forma de pagamento</option>
						<option value="PIX">PIX</option>
						<option value="Boleto Bancário">Boleto Bancário</option>
						<option value="Cartão de crédito Parcelado">Cartão de crédito Parcelado</option>
					`;

					contentCurso.find('#selectOpcoesPagamento').html(pagamento);
				}

				// console.log(dataInicio)

				setTimeout(function()
				{
					if(dataInicio && dataInicio != "")
					{
						$('.form.inscricao').find('input#inputDataCurso').val(dataInicio);
					}
				}, 2000);
			}
		});
	}
}

$(function() {

	slides();
	rescaleVideo();
	menuMobile();
	calendarios();
	ConactForm();

	$(window).resize(function(){
		rescaleVideo();
		menuMobile();
		propBox();
	});

	$(window).on('load',function(){
		propBox();
		calendarioStylize();
		
		// if($("#muteYouTubeVideoPlayer") && $("#muteYouTubeVideoPlayer")[0].length > 0)
		// {
		// 	$("#muteYouTubeVideoPlayer")[0].play();
		// }
	});

});